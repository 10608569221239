<template>
  <div class="szkg_box" ref="szkg_box">
    <h1>经管学院师资队伍概况</h1>
    <nav>{{teaching_staff_}}</nav>
    <div class="tacNumber">
      <section v-for="item in szkgData" :key="item.id">
        <div class="top"><a></a><span><em>{{item.tip.slice(0,7)}}</em><i>{{item.tip.slice(7)}}</i></span></div>
        <div class="bottom">
          <div class="number">{{item.value}}</div>
          <span>人</span>
        </div>
      </section>
      <div class="end">数据截止 {{data_cutoff_}}</div>
    </div>
  </div>
</template>

<script setup>
import { ref,onMounted,watch, nextTick, reactive } from 'vue'
import * as axios from '@/api/API.js'
// 数字滚动函数
let Scrollnumbers = (num, o) => {
  num.value = num.value < 100 ? 1 : 50
  const inter = setInterval(() => {
    num.value += 1
    if (num.value == o) {
      clearInterval(inter)
    } 
  }, 25)
}

let szkgData = reactive([])
let teaching_staff_ = ref('')
let data_cutoff_ = ref('')
const szkg_box = ref(null)

let getSzkglistdata = async () => {
  const {data:res} = await axios.getSzkglist()
  if(res.code == 200){
    teaching_staff_.value = res.data.value
    data_cutoff_.value = res.data.data_cutoff
    const resdata = [
    {value:res.data.full_time_teacher,tip:'经济与管理学院副教授'},
    {value:res.data.graduate_teacher,tip:'经济与管理学院教授'},
    {value:res.data.professor,tip:'经济与管理学院研究生导师'},
    {value:res.data.associate_professor,tip:'经济与管理学院专任教师'}
    ]
    szkgData.push(...resdata)

    szkg_box.value.style.opacity = 1
    szkg_box.value.style.margin = '0'
  
    szkgData.forEach(items => {
    Scrollnumbers(items, items.value)
    })
  }
}
getSzkglistdata()

onMounted(()=>{
szkg_box.value.style.opacity = 0
szkg_box.value.style.margin = '500px 0 0 0'
})



</script>

<style lang="less" scoped>
@maxw:128rem;
.szkg_box{
  transition: ease .8s;
  h1{
    box-sizing: border-box;
    font-size: 39/@maxw;
    font-family: fantBold;
    font-weight: 300;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    margin: 0;
    padding: 0 0 20/@maxw 0;
  }
  nav{
    margin-top: 60/@maxw;
    font-size: 24/@maxw;
    font-family: Source Han Sans CN-Medium;
    font-weight: 500;
    color: #5A5A5A;
  }
  .tacNumber{
    width: 1080/@maxw;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -20/@maxw;
    section{
      min-width: 300/@maxw;
      max-width: 500/@maxw;
      flex: 1;
      height: 268/@maxw;
      margin: 96/@maxw 0 50/@maxw  0;
      .top{
        display: flex;
        align-items: center;
        width: 176/@maxw;
        height: 52/@maxw;
        a{
          display: inline-block;
          width: 9px;
          height: 100%;
          background-color: #B19A86;
        }
        span{
          color: #B19A86;
          font-size: 22/@maxw;
          margin-left: 15/@maxw;
          >i{
            font-style: normal;
            font-weight:bold;
          }
          >em{
            font-style: normal;
            white-space: nowrap;
          }
        }
      }
      .bottom{
        display: flex;
        color: #B19A86;
        width: 450/@maxw;
        overflow: hidden;
        white-space: nowrap;
        .number{
          font-size: 193/@maxw;
          color: #B19A86;
          font-family: Source Han Sans CN-Medium;
          font-weight: 100 !important;
        }
        span{
          font-size: 54/@maxw;
          align-self:flex-end;
          margin-bottom: 50/@maxw !important;
        }
      }
    }
    >section:nth-child(2n){
      margin: 96/@maxw 200/@maxw 0 190/@maxw;
    }
    .end{
      margin-top: 80/@maxw;
      font-size: 24/@maxw;
      color: #B19A86;
    }
  }
}
</style>